.react-datepicker-popper {
    z-index: 10001;
    transition: all 0.2s;
}

.react-datepicker {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 0.95em !important;
    color: #1a202c;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
    transition: all 0.2s;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block;
    transition: all 0.2s;
}

.react-datepicker__input-container:focus-within {
    z-index: 1;
    border-color: rgba(138, 130, 122, 0.5);
    box-shadow: 0 0 0 1px rgba(138, 130, 122, 0.5);
    transition: all 0.2s;
}

.react-datepicker__input-container > input {
    width: 100%;
    height: 100%;
    outline: 0;
    transition: all 0.2s;
    font-size: 1rem;
    height: 2.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 0.25rem;
    border: 1px solid;
    border-color: #E2E8F0;
    transition: all 0.2s;
}
.react-datepicker__input-container > input:disabled {
    color: #cbd5e0;
    border-color: rgb(226, 232, 240);
    transition: all 0.2s;
    background-color: transparent !important;
}

div.react-datepicker-wrapper input:disabled {
    color: #cbd5e0;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    height: 8px;
}

.react-datepicker__navigation--previous {
    border-right-color: #818181;
    margin-top: 4px;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #626262;
}

.react-datepicker__navigation--next {
    border-left-color: #818181;
    margin-top: 4px;
}

.react-datepicker__navigation--next:hover {
    border-left-color: #626262;
}

.react-datepicker__header {
    background: #f3f3f2 !important;
}

.react-datepicker__header,
.react-datepicker__time-container {
    border-color: #f3f3f2;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-size: 1.3em !important;
    font-weight: 500 !important;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    margin: 0 1px 0 0;
    height: auto;
    padding: 7px 10px;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background: #f3f3f2;
}

.react-datepicker__day:hover {
    background: #c2c2c1;
    border-radius: 50% !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background: #818181 !important;
    color: black !important;
    font-weight: normal;
    border-radius: 50% !important;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover
    .react-datepicker__day--keyboard-selected {
    background: #626262 !important;
    color: black !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background: #c2c2c1 !important;
    color: black !important;
    border-radius: 50% !important;
}

.react-datepicker__close-icon::after {
    background-color: unset;
    border-radius: unset;
    font-size: 1.5rem;
    font-weight: bold;
    color: hsl(0, 0%, 80%);
    height: 20px;
    width: 20px;
}

.react-datepicker__close-icon::after:hover {
    color: hsl(0, 0%, 70%);
}
